import { RouteConstants } from "../constants/Routes";

import api from "./apiConfig";

/**
 * Update a product
 * 
 * @param {*} productNameId The product's productNameId
 * @param {*} productData The new data for the product
 * @returns The response to the update request
 */
export const updateProductByProductNameId = async (productNameId, productData) => 
{
    try {
        const response = await api.put(`${RouteConstants.PRODUCTS_URL}/${productNameId}`, productData)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}

/**
 * Upload a new product
 * 
 * @param {*} productData The new data for the product
 * @returns  The response to the upload request
 */
export const uploadProduct = async (productData) => 
{
    try {
        const response = await api.post(`${RouteConstants.PRODUCTS_URL}`, productData)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}

/**
 * Send a request to the backend to get all the products that aren't accounts.
 * 
 * @returns Array of products that aren't accounts.
 */
export const getProductByProductNameId = async (productNameId) => 
{
    try {
        const response = await api.get(`${RouteConstants.PRODUCTS_URL}/${productNameId}`)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}

/**
 * Send a request to the backend to get all the products that aren't accounts.
 * 
 * @returns Array of products that aren't accounts.
 */
export const getProducts = async (type) => 
{
    try {
        const response = await api.get(`${RouteConstants.PRODUCTS_URL}/type/${type}`)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}


/**
 * Send a request to the backend to get all the products that aren't accounts.
 * 
 * @returns Array of products that aren't accounts.
 */
export const deleteProductById = async (productId) => 
{
    try {
        const response = await api.delete(`${RouteConstants.PRODUCTS_URL}/${productId}`)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}