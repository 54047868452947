/**
 * Author: GreatAlts
 * 
 * Purpose: Store the code for the navbar in the dashboard.
 */
import { Link } from "react-router-dom"
import "./navbar.css"

export default function Navbar()
{
    return (
        <div className="navbar-cont">
            <div className="navbar-tabs">
                <Link to={"/"} className="navbar-item-cont">
                    Overview
                </Link>
                <Link to={"/accounts"} className="navbar-item-cont">
                    Accounts
                </Link>
                <Link to={"/products"} className="navbar-item-cont">
                    Products
                </Link>
                <Link to={"/orders"} className="navbar-item-cont">
                    Orders
                </Link>
                <Link to={"/blacklists"} className="navbar-item-cont">
                    Blacklist
                </Link>
            </div>
            <div className="navbar-actions">
                <div className="navbar-logout">Logout</div>
            </div>
        </div>
    )
}