import { useState }         from "react";

import { uploadAccount }    from "../../services/accounts";
import { useAccounts } from "../../context/AccountsContext";

export default function UploadAccount()
{
    const { accounts }                      = useAccounts();

    const [ accountData, setAccountData ]   = useState({
        username: "",
        price: "",
        gamemode: "bedwars",
        prename: false,
        unbanned: true,
        serial: "",
        capes: [],
        tags: ["Full Access"]
    });

    const [ tagInput, setTagInput ]         = useState("");

    const capes                             = ["Minecraft Experience", "Cherry Blossom", "MCC 15th Year", "Mojang Office", "Migrator", "Vanilla", "Follower's", "15th Anniversary", "Purple Heart", "Home", "Menace"];

    const handleChange = (e) =>
    {
        const { name, value, type, checked } = e.target;

        if (name === "tags") {
            setAccountData(prevState => ({
                ...prevState, tags: value.split("\n")
            }));
        } else {
            setAccountData(prevState => ({
                ...prevState,
                [name]: type === "checkbox" ? checked : value,
            }));
        }

        if (name === "serial")
        {
            updateCapesUsernameAndPrenameFromSerial(value);
        }
    };

    const updateCapesUsernameAndPrenameFromSerial = (serialValue) => {
        // Find all matching capes in the serial string
        const foundCapes = capes.filter(cape => serialValue.toLowerCase().includes(cape.toLowerCase()));

        // Check if "prename" exists in the serial string
        const containsPrename = serialValue.toLowerCase().includes("prename");

        let tempUsername = accountData.username;
        if(tempUsername === "")
            tempUsername = serialValue.split(" ")[2];

        setAccountData(prevState => ({
            ...prevState,
            username: tempUsername,
            serial: serialValue,
            capes: foundCapes,
            prename: containsPrename
        }));
    };

    const addCape = (e) =>
    {
        const { name, checked } = e.target;

        setAccountData(prevState =>
        {
            const updatedCapes = checked 
                ? [...prevState.capes, name] 
                : prevState.capes.filter(cape => cape !== name);
            return { ...prevState, capes: updatedCapes };
        });
    };
    
    const addTag = () => 
    {
        if (tagInput.trim() !== "" && !accountData.tags.includes(tagInput))
        {
            setAccountData(prevState => ({
                ...prevState,
                tags: [...prevState.tags, tagInput.trim()]
            }));
            setTagInput("");
        }
    };

    const removeTag = (tagToRemove) =>
    {
        setAccountData(prevState => ({
            ...prevState,
            tags: prevState.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Send a request to upload the account
        const uploadResponse = await uploadAccount(accountData);

        switch(uploadResponse.status) {
            case 201:
                const uploadStatus = uploadResponse.data;

                let uploadStatusString = `Account Saved: ${uploadStatus.accountSaved}\nProduct Saved: ${uploadStatus.productSaved}\nSerial Saved: ${uploadStatus.serialSaved}\nDiscord Posted: ${uploadStatus.discordPosted}`;
                alert(uploadStatusString);

                if (
                    uploadStatus.accountSaved &&
                    uploadStatus.productSaved &&
                    uploadStatus.serialSaved
                ) {
                    
                    // Reset the form
                    setAccountData({
                        username: "",
                        price: "",
                        gamemode: "bedwars",
                        prename: false,
                        unbanned: true,
                        serial: "",
                        capes: [],
                        tags: ["Full Access"]
                    });
                }

                accounts.push(uploadStatus.product);
                break;

            case 500:
                alert("INTERNAL SERVER ERROR");
                break;
            default:
                alert("UNKNOWN ERROR");
                break;
            
        }

        console.log("Form Data Submitted:", accountData);
    };

    return (
        <div className="upload-account-page">
            <h2 className="upload-account-title">Upload Account</h2>
            <form onSubmit={handleSubmit} className="upload-account-form">
                <div className="upload-account-input-cont">
                    <label className="upload-account-label">Username:</label>
                    <input type="text" name="username" placeholder="Enter Account Username" value={accountData.username} onChange={handleChange} className="upload-account-input" required />
                </div>
                <div className="upload-account-input-cont">
                    <label className="upload-account-label">Price:</label>
                    <input type="number" name="price" placeholder="Enter Account Price" value={accountData.price} onChange={handleChange} className="upload-account-input" required />
                </div>
                <div className="upload-account-input-cont">
                    <label className="upload-account-label">Game Mode:</label>
                    <select name="gamemode" value={accountData.gamemode} onChange={handleChange} className="upload-account-gamemodes-cont">
                        <option value="bedwars">Bedwars</option>
                        <option value="caped">Caped</option>
                        <option value="skywars">Skywars</option>
                        <option value="skyblock">Skyblock</option>
                        <option value="duels">Duels</option>
                    </select>
                </div>
                <div className="upload-account-capes-cont">
                    <label className="upload-account-label">Capes</label>
                    <div className="upload-account-cape-option-cont">
                        {capes.map(cape => (
                            <div key={cape} className="upload-account-cape-option">
                                <input type="checkbox" name={cape} checked={accountData.capes.includes(cape)} onChange={addCape} className="mr-2" />
                                <label className="upload-account-label">{cape}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="upload-account-capes-cont">
                    <label className="upload-account-label">Extra Info</label>
                    <div className="upload-account-cape-option-cont">
                        {["prename", "unbanned"].map(extraInfo => (
                            <div key={extraInfo} className="upload-account-cape-option">
                                <input type="checkbox" name={extraInfo} checked={accountData[extraInfo]} onChange={addCape} className="mr-2" />
                                <label className="upload-account-label">{extraInfo.charAt(0).toUpperCase() + extraInfo.slice(1)}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="upload-account-input-cont">
                    <label className="upload-account-label">Serial:</label>
                    <input type="text" name="serial" value={accountData.serial} onInput={handleChange} placeholder="Enter Serial" className="upload-account-input" required />
                </div>
                <div className="upload-account-input-cont">
                    <label className="upload-account-label">Tags:</label>
                    <div className="upload-account-tags">
                        {accountData.tags.map(tag => (
                            <span key={tag} className="upload-account-tag">
                                {tag} <button type="button" onClick={() => removeTag(tag)}>x</button>
                            </span>
                        ))}
                    </div>
                    <div className="upload-account-input-tag-cont">
                        <input type="text" value={tagInput} onChange={(e) => setTagInput(e.target.value)} className="upload-account-tag-input" placeholder="Add a tag" />
                        <button type="button" onClick={addTag} className="upload-account-tag-button">Add Tag</button>
                    </div>
                </div>
                <button type="submit" className="upload-account-submit">Submit</button>
            </form>
        </div>
    );
}
