import { Routes, Route }  from "react-router-dom";

import Navbar             from "./components/navbar/navbar";
import Products           from "./components/products/products";
import Serials            from "./components/serials/Serials";
import Orders             from "./components/orders/orders";
import Accounts           from "./components/accounts/accounts";
import UploadAccount      from "./components/accounts/upload";
import ProductEditForm    from "./components/products/ProductEditForm";
import Overview           from "./components/overview/Overview";

function App() {
  return (
    <div className="dashboard-cont">
      <Navbar />
      <div className="dashboard-page-cont">
        <Routes>
          <Route path={"/*"} element={<Overview /> } />
          <Route path={"/accounts/*"} element={<Accounts />} />
          <Route path={"/accounts/upload"} element={<UploadAccount />} />

          <Route path={"/products/serial/*"} element={<Serials />} />
          <Route path={"/products/upload"} element={<ProductEditForm />} />
          <Route path={"/products/update/*"} element={<ProductEditForm />} />
          <Route path={"/products/*"} element={<Products />} />

          {/* <Route path={"/orders/*"} element={<Orders/>}/> */}
          <Route path={"/blacklists/*"} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
