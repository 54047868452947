import { useNavigate }          from "react-router-dom";
import { useState }             from "react";

import { deleteProductById }    from "../../services/products";

import { useAccounts }          from "../../context/AccountsContext";
import { useAuth }              from "../../context/AuthContext";
import { useAlert }             from "../../context/AlertContext";

export default function Accounts()
{
    const { accounts, setAccounts }         = useAccounts();
    const { hasRole }                       = useAuth();

    const [ account, setAccount ]           = useState({});
    const [ modalType, setModalType ]       = useState("none");
    const [ title, setTitle ]               = useState("");
    const [ description, setDescription ]   = useState("");
    const [ serial, setSerial ]             = useState("");

    const navigate = useNavigate();

    const handleDelete = async (productId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this product?");
        if (!confirmDelete) return;

        try {
            await deleteProductById(productId);
            setAccounts(accounts.filter((accounts) => accounts.productId !== productId));
        } catch (error) {
            console.error("Failed to delete product:", error);
            alert("Error deleting product.");
        }
    };

    const createDescription = (account) => {
        const hypixelRank = account.hypixelRank;
        const hypixelLevel = account.hypixelLevel;
        const bedwarsLevel = account.bedwarsLevel;

        return (
            <div>
                <div>✦If any issues or questions occur, please contact me and I will respond as soon as possible.✦</div>
                <p>DESCRIPTION</p>
                <div>Full access means you can change the email of the account to your own which gives you 100% ownership of the account permanently.</div>
                <div>[✯] Permanent Premium Java + Bedrock Edition</div>
                <div>[✯] Hypixel Unbanned</div>
                <div>[✯] Not Gamepass</div>
                <div>[✯] {hypixelRank} Rank on Hypixel</div>
                <div>[✯] {hypixelLevel} Level on Hypixel (NOT SKYBLOCK)</div>
                <div>[✯] {bedwarsLevel} Bedwars Stars</div>
                {account.tags.map(tag => {
                        return <div>[✯] {tag}</div>
                    })}
                {account.capes.map(cape => {
                        console.log(cape)
                        return <div>[✯] {cape} Cape</div>
                    })}

                <p>✦✦✦WARNING✦✦✦
                If you or anyone in your house has ever been banned on Hypixel before, you must change your IP before entering Hypixel. If you ignore this message, you may security banned on Hypixel and we may not be able to refund or reimburse you if this happens.</p>
            </div>
        )
    }

    const createG2GSerial = (serial) => {
        return (
            <div>
                <div>READ THIS TO CHANGE THE ACCOUNT EMAIL TO YOUR OWN!</div>
        
                <div><br/>Email: {serial.split(":")[0]}</div>
                <div><br/>Password: {serial.split(":")[1].split("@")[0]}</div>
                <div>Recovery Code: {serial.split(":")[2].split(" ")[0]}</div>

                <p>INSTRUCTIONS (FOLLOW ALL STEPS)<br/>
                ==================================<br/>
                tutorials-73375.web.app<br/>
                ==================================</p>

                Please contact me if there are any issues.
            </div>)
    }

    const createTitle = (product, type) => {
        if(type === "eldorado")
            return `[${product?.account.hypixelRank}]🌟IGN: ${product.title}🌟${product.account.bedwarsLevel} Bedwars (BW) Stars🚀${product.account.hypixelLevel} Hypixel Level (NOT SKYBLOCK)🌟Hypixel Unbanned🌟Full Access Java + Bedrock Edition🌟NOT GAMEPASS`;
        else if(type === "g2g")
            return `[${product?.account.hypixelRank}] | IGN: ${product.title} | ${product.account.bedwarsLevel} Bedwars (BW) Stars | ${Math.trunc(product.account.hypixelLevel)} Hypixel Level | Hypixel Unbanned | Full Access | NOT GAMEPASS`
    }

    const createEldoradoSerial = (serial) => {
        return (
            <div>
                <div>Username: {serial.split(" ")[2]}</div>
                <div>Email: {serial.split(":")[0]}</div>
                <div>Password: {serial.split(":")[1].split("@")[0]}</div>
                <div>Recovery Code: {serial.split(":")[2].split(" ")[0]}</div>
                <p>
                ==========================================<br/>
                Watch the video and follow ALL steps in the video to securely change the account information to your own.<br/>
                <br/>
                Video instructions on how to use recovery code: https://youtu.be/Ss0AltF0iGs<br/>
                ==========================================</p>

                <p>If there are any issues please contact us.</p>
                <div>If everything is good. Please click "Mark as Received" -> Check all boxes -> "Mark as Received" -> Click the Green thumbs up button.</div>

                <div>Thank you!</div>
            </div>)
    }

    const setEldorado = (account) => {
        setModalType("eldorado");
        setAccount(account);

        setTitle(createTitle(account, "eldorado"));
        setSerial(createEldoradoSerial(account.account.serial));
        setDescription(createDescription(account.account));
    }

    const setG2G = (account) => {
        setModalType("g2g");
        setAccount(account);

        setTitle(createTitle(account, "g2g"));
        setSerial(createG2GSerial(account.account.serial));
        setDescription(createDescription(account.account));
    }

    return (
        <div className="product-page-cont">
            <div className="product-page-header-cont">
                <h2 className="dashboard-page-header">Accounts</h2>
                <div className="product-page-item" onClick={() => navigate(`/accounts/upload`)}>Upload Account</div>
            </div>

            <div className="product-page-products-cont">
                <table className="accounts-table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Checkouts</th>
                            <th>Price</th>
                            <th>Gamemode</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {accounts.map((account) => (
                            <tr key={account.productId}>
                                <td>
                                    <div>
                                        <strong>{account.title}</strong>
                                        <div style={{ fontSize: "0.8rem", color: "gray" }}>{account.account?.uuid}</div>
                                    </div>
                                </td>
                                <td>{account.checkouts}</td>
                                <td>${account.price}</td>
                                <td>{account.account?.gamemode ? account.account.gamemode.charAt(0).toUpperCase() + account.account.gamemode.slice(1) : "N/A"}</td>                        
                                <td className="accounts-action-actions">
                                    {hasRole("ADMIN") && <button className="account-action-button" onClick={() => setG2G(account)}>
                                        G2G
                                    </button>}
                                    {hasRole("ADMIN") && <button className="account-action-button" onClick={() => setEldorado(account)}>
                                        Eldorado
                                    </button>}
                                    <button className="account-action-button" >
                                        Edit
                                    </button>
                                    <button className="account-delete-button" onClick={() => handleDelete(account.productId)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {modalType !== "none" && <InfoModal product={account} modalType={modalType} setModalType={setModalType} title={title} serial={serial} description={description}/>}
        </div>
    );
}

function InfoModal({ modalType, setModalType, title, serial, description })
{
    const { addAlert }                  = useAlert();

    const handleCopy = async (e) => {
        try {
            await navigator.clipboard.writeText(e.currentTarget.innerText);
            addAlert("Copied", "success", 3000)
        } catch (err) {
            console.error("Failed to copy:", err);
        }
    };

    return (
        <div className="g2g-eldorado-cont">
            <div className="modal-overlay">
                {modalType === "g2g" &&
                    <>
                        <h3>G2G Title</h3>
                        <div className="modal-content" onClick={(e) => handleCopy(e)}>
                            {title}
                        </div>
                        <h3>G2G Serial</h3>
                        <div className="modal-content" onClick={(e) => handleCopy(e)}>
                            {serial}
                        </div>
                    </>}

                {modalType === "eldorado" &&
                    <>
                        <h3>Eldorado Title</h3>
                        <div className="modal-content" onClick={(e) => handleCopy(e)}>
                            {title}
                        </div>
                        <h3>Eldorado Serial</h3>
                        <div className="modal-content" onClick={(e) => handleCopy(e)}>
                            {serial}
                        </div>
                    </>}

                <h3>Description</h3>
                <div className="modal-content" onClick={(e) => handleCopy(e)}>
                    {description}
                </div>

                <button onClick={() => setModalType("none")} className="modal-close">Close</button>
            </div>
        </div>
    )
}