import { deleteSerialBySerialId, updateSerialBySerialId }  from "../../services/serials";

import { useAlert }                     from "../../context/AlertContext";

import { copyToClipboard }              from "../../util/clipboard";
import { useState } from "react";

export default function SerialItem({serial, index})
{
    const { addAlert }                      = useAlert();

    const [ serialStatus, setSerialStatus ]     = useState("copy");
    const [editedSerialId, setEditedSerialId] = useState(serial.serialId);

    const deleteSerial = async (serialId) =>
    {
        const response = await deleteSerialBySerialId(serialId);

        if(response.status === 200)
        {
            alert("Serial Deleted");
        }else{
            alert("Error");
        }
    }

    const handleConfirmEdit = async () => 
    {
        const tempSerialId = serial.serialId;
        serial.serialId = editedSerialId;
        const response = await updateSerialBySerialId(tempSerialId, serial);
        if(response.status === 200)
        {
            addAlert("Serial Updated");
        }else{
            addAlert("Error", "error", 3000);
        }

        setSerialStatus("copy");
    };

    return (
        <div key={serial.id} className="serial-item">
            <div className="serial-count">
                {index + 1}
            </div>
            <div className="serial-count">
                {serial.uploader.substring(0, 3)}
            </div>
            <div className="serial-info-cont">
            {serialStatus === "copy" ? (
                <div 
                    className="serial-id" 
                    onClick={serialStatus === "copy" ? () => copyToClipboard(serial.serialId, addAlert) : undefined}
                    >
                    {serial.serialId}
                </div> ) : (
                    <input
                    type="text"
                    className="serial-input"
                    value={editedSerialId}
                    onChange={(e) => setEditedSerialId(e.target.value)}
                  />
                ) 
            }
                {serialStatus === "copy" && (
                    <div className="serial-edit" onClick={() => setSerialStatus("edit")}>
                        <svg 
                            viewBox="0 0 420 380" 
                            width="16"
                            height="16"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg" 
                            id="fi_1159633"
                            >
                            <path d="M370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" />
                            <path d="M376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" />
                        </svg>
                    </div>)}
                {serialStatus === "edit" && (<div className="serial-confirm" onClick={handleConfirmEdit}>
                <svg 
                    version="1.1" 
                    id="fi_1055183" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 511.985 511.985" 
                    width="16" 
                    height="16"
                    fill="white"
                    xmlSpace="preserve"
                    >
                    <path d="M500.088,83.681c-15.841-15.862-41.564-15.852-57.426,0L184.205,342.148L69.332,227.276c-15.862-15.862-41.574-15.862-57.436,0c-15.862,15.862-15.862,41.574,0,57.436l143.585,143.585c7.926,7.926,18.319,11.899,28.713,11.899c10.394,0,20.797-3.963,28.723-11.899l287.171-287.181C515.95,125.265,515.95,99.542,500.088,83.681z" />
                </svg>
                </div>)}
                {serialStatus === "edit" && (<div className="serial-cancel" onClick={() => setSerialStatus("copy")}>
                    <svg 
                        height="16"
                        width="16"
                        fill="white"
                        viewBox="0 0 365.696 365.696" 
                        xmlns="http://www.w3.org/2000/svg" 
                        id="fi_1828774">
                            <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"></path>
                    </svg>
                </div>)}
            </div>
            <div className="serial-delete" onClick={() => deleteSerial(serial.serialId)}>
                <svg 
                    fill="white"  
                    viewBox="0 0 32 32" 
                    height="16"
                    width="16" 
                    xmlns="http://www.w3.org/2000/svg" 
                    id="fi_2782988">
                    <g id="Layer_2" data-name="Layer 2">
                        <path d="m3 7h2v20.48a3.53 3.53 0 0 0 3.52 3.52h15a3.53 3.53 0 0 0 3.48-3.52v-20.48h2a1 1 0 0 0 0-2h-26a1 1 0 0 0 0 2zm22 0v20.48a1.52 1.52 0 0 1 -1.52 1.52h-15a1.52 1.52 0 0 1 -1.48-1.52v-20.48z"></path>
                        <path d="m12 3h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2z"></path>
                        <path d="m12.68 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z"></path>
                        <path d="m19.32 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z"></path>
                    </g>
                </svg>
            </div>
        </div>
    )
}