import { BrowserRouter as Router }  from 'react-router-dom';
import React                        from 'react';
import ReactDOM                     from 'react-dom/client';
import App                          from './App';

import { AccountProvider }          from './context/AccountsContext';
import { AuthProvider }             from './context/AuthContext';
import { ProductProvider }          from './context/ProductsContext';

import './index.css';

import './styles/dashboard.css';

import './styles/overview/overview.css';
import './styles/products/productEditForm.css';

import './styles/accounts/upload.css';
import './styles/accounts/accountPage.css';
import { AlertProvider } from './context/AlertContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AccountProvider>
    <ProductProvider>
      <AuthProvider>
        <AlertProvider>
          <Router>
            <App />
          </Router>
        </AlertProvider>
      </AuthProvider>
    </ProductProvider>
  </AccountProvider>
);