import { RouteConstants } from "../constants/Routes";

import api from "./apiConfig";

/**
 * Send a /POST request to upload an account
 * 
 * @returns The response of the HTTP request
 */
export const uploadAccount = async (account) => 
{
    try {
        const response = await api.post(`${RouteConstants.ACCOUNT_URL}`, account)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}