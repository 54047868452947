import { getSerialsByProductNameId, uploadSerialsByProductNameId }  from "../../services/serials";

import { useEffect, useState }          from "react"

import SerialItem from "./SerialItem";

import "./serials.css";

export default function Serials()
{
    const [ formData, setFormData ]         = useState({});
    const [ serials, setSerials ]           = useState([]); 

    const productNameId = window.location.pathname.split("/")[3];

    const onLoad = async () =>
    {
        const serials = await getSerialsByProductNameId(productNameId);
        console.log(serials);
        setSerials(serials.data);
    }

    useEffect(() => {
        onLoad();
    }, [])

    const uploadSerials = async () =>
    {
        if(!productNameId)
            return alert("Select a Product");

        if(!formData.serialId)
            return alert("Enter Serials");

        const response = await uploadSerialsByProductNameId(formData, productNameId);

        if(response.status === 201)
        {
            alert(`Uploaded ${response.data} serials`);
        }else{
            alert("Error");
        }
        window.location.reload();
    }
    
    const handleChange = (e) => 
    {
        let { name, value } = e.target;
        setFormData({ [name]: value });
    }

    return (
        <div className="serial-upload-page">
            <div className="product-page-header-cont">
                <h2 className="dashboard-page-header">Upload Serials</h2>
                <div className="serial-upload-button" onClick={uploadSerials}>Upload Serials</div>
            </div>
            <div className="serial-uploader-cont">
                <textarea name="serialId" className='serial-uploader-text-area' onChange={handleChange}/>
            </div>
            <div className="serials-cont">
                {
                    serials.map((serial, index) => (
                        <SerialItem serial={serial} index={index} />
                    ))
                }
            </div>
        </div>
    )
}