import { createContext, useContext, useState, useEffect } from "react";

import { getCookie } from "../util/cookies";
import { parseJwt } from "../util/jwt";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    const hasRole = (role) => {
        const token = getCookie("UTOK");

        if (!token) return false;

        const decoded = parseJwt(token);

        return decoded.authorities.includes(`ROLE_${role}`);
    }

    useEffect(() =>
    {
        const token = getCookie("UTOK")
        if (token)
        {
            try {
                const decoded = parseJwt(token);
                console.log(decoded)
                if(!decoded.authorities.includes("UPLOADER"))
                    window.location.href = "https://altsgreat.com";

                setAuth(decoded);
            } catch (error) {
                window.location.href = "https://altsgreat.com";
            }
        }else{
            window.location.href = "https://altsgreat.com";
        }
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, hasRole }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);