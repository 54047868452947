import { createContext, useContext, useState, useEffect }   from "react";

import { getProducts }                                      from "../services/products";

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
    const [accounts, setAccounts] = useState([]);

    useEffect(() =>
    {
        const fetchAccounts = async () =>
        {
            try {
                const retrievedProducts = await getProducts("accounts");

                setAccounts(retrievedProducts.data);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchAccounts();
    }, []);

    return (
        <AccountContext.Provider value={{ accounts, setAccounts }}>
            {children}
        </AccountContext.Provider>
    );
};

export const useAccounts = () => useContext(AccountContext);