import { createContext, useContext, useState, useEffect }   from "react";

import { getProducts }                                      from "../services/products";

const ProductContext = createContext();

export const ProductProvider = ({ children }) =>
{
    const [products, setProducts] = useState([]);

    useEffect(() =>
    {
        const fetchProducts = async () =>
        {
            try {
                const retrievedProducts = await getProducts("regular");

                setProducts(retrievedProducts.data);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <ProductContext.Provider value={{ products, setProducts }}>
            {children}
        </ProductContext.Provider>
    );
};

export const useProducts = () => useContext(ProductContext);