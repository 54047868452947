import { useEffect } from "react"

export default function Overview()
{
    useEffect(() => {
        
    })

    return (
        <>
            <div className="overview-page-cont">
                <div className="overview-store-settings">
                    <div className="overview-store-item">
                        <h3 className="overview-s-i-header">
                            Payments
                        </h3>
                        <div className="overview-s-i-body">
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    Venmo
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    PayPal
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    Zelle
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    LTC
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    BTC
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    ETH
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                            <div className="overview-s-i-b-item">
                                <div className="overview-s-i-b-title">
                                    SOL
                                </div>
                                <div className="overview-s-i-b-content">
                                    <input 
                                        className="overview-s-i-b-input"
                                        placeholder="Enter PayPal Email"/>
                                    <button className="overview-s-i-b-action">Edit</button>
                                    <button className="overview-s-i-b-action">Cancel</button>
                                    <button className="overview-s-i-b-action">Save</button>
                                    <button className="overview-s-i-b-action">Enable</button>
                                    <button className="overview-s-i-b-action">Disable</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overview-payouts">
                    <div className="overview-payouts">
                        <div className="overview-p-section">
                            <div className="overview-p-header">
                                <div className="overview-p-h-name">
                                    Great
                                </div>
                                <div className="overview-p-h-amount">
                                    Total: $9.99
                                </div>
                            </div>
                            <div className="overview-p-calculations">
                                <div className="overview-p-c-section">
                                    <div className="overview-p-c-s-header">

                                    </div>
                                    <div className="overview-p-c-s-amount">

                                    </div>
                                    <div className="overview-p-c-s-fee">

                                    </div>
                                    <div className="overview-p-c-s-total">

                                    </div>
                                </div>
                                <div className="overview-p-c-section">
                                    <div className="overview-p-c-s-header">

                                    </div>
                                    <div className="overview-p-c-s-amount">

                                    </div>
                                    <div className="overview-p-c-s-fee">

                                    </div>
                                    <div className="overview-p-c-s-total">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overview-p-section">
                            <div className="overview-p-header">
                                <div className="overview-p-h-name">
                                    Cacti
                                </div>
                                <div className="overview-p-h-amount">
                                    Total: $9.99
                                </div>
                            </div>
                            <div className="overview-p-calculations">
                                <div className="overview-p-c-section">
                                    <div className="overview-p-c-s-header">

                                    </div>
                                    <div className="overview-p-c-s-amount">

                                    </div>
                                    <div className="overview-p-c-s-fee">

                                    </div>
                                    <div className="overview-p-c-s-total">

                                    </div>
                                </div>
                                <div className="overview-p-c-section">
                                    <div className="overview-p-c-s-header">

                                    </div>
                                    <div className="overview-p-c-s-amount">

                                    </div>
                                    <div className="overview-p-c-s-fee">

                                    </div>
                                    <div className="overview-p-c-s-total">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}