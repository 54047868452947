import { RouteConstants } from "../constants/Routes";

import api from "./apiConfig";

export const getSerialsByProductNameId = async (productNameId) => {
    try {
        const response = await api.get(`${RouteConstants.SERIAL_URL}/${productNameId}`);
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const uploadSerialsByProductNameId = async (serials, productNameId) => {
    try {
        const response = await api.post(RouteConstants.SERIAL_URL + "/" + productNameId, serials);
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const updateSerialBySerialId = async (serialId, newSerial) => {
    try {
        const response = await api.put(RouteConstants.SERIAL_URL + "/" + serialId, newSerial);
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const deleteSerialBySerialId = async (serialId) => {
    try {
        const response = await api.delete(`${RouteConstants.SERIAL_URL}/${serialId}`);
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}