

export const RouteConstants =
{
    BACKEND_URL: "http://localhost:8080",
    FRONTEND_URL: "http://localhost:3000",

    BACKEND_URL: "https://altsgreat-72x9.onrender.com",
    FRONTEND_URL: "https://dashboard.altsgreat.com",

    SECURER: "/securer",
    LOGIN_API_URL: "/user",
    REGISTER_URL: "/register",
    AUTHORITIES_URL: "/authorities",
    PRODUCTS_URL: "/products",
    INVOICES_URL: "/invoices",
    USERS_URL: "/users",
    SERIAL_URL: "/serials",
    ACCOUNT_URL: "/accounts",
    REFERENCE_URL: "/references",
    VERIFY_URL: "/verify",
    CURRENCY_URL: "/api/currency/rates",
    DISCORD_URL: "/api/discord"
}