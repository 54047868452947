import { getProductByProductNameId, updateProductByProductNameId, uploadProduct }    from "../../services/products";

import { useEffect, useState }          from "react";
import { useNavigate }                  from "react-router-dom";

import { useAlert } from "../../context/AlertContext";

export default function ProductEditForm()
{
    const navigate = useNavigate();
    const { addAlert } = useAlert();
    const [product, setProduct] = useState({
        title: "",
        price: "",
        quantity: "",
        longTitle: "",
        enhancedDescription: "",
        productNameId: "",
        imageUrl: "",
        minimum: "",
        maximum: "",
        discounts: []
    });

    const formType = window.location.pathname.split("/")[2];
    const productNameId = window.location.pathname.split("/")[3];

    useEffect(() => 
    {
        if(formType === "update")
        {
            const fetchProduct = async () => {
                try {
                    const productResponse = await getProductByProductNameId(productNameId);
                    const productData = productResponse.data;
                    setProduct({
                        title: productData.title || "",
                        price: productData.price || "",
                        quantity: productData.quantity || 0,
                        longTitle: productData.longTitle || "",
                        enhancedDescription: productData.enhancedDescription || "",
                        productNameId: productData.productNameId || "",
                        imageUrl: productData.imageUrl || "",
                        minimum: productData.minimum || 1,
                        maximum: productData.maximum || "",
                        discounts: productData.discounts || []
                    });
                } catch (error) {
                    console.error("Failed to fetch product:", error);
                }
            };

            fetchProduct();
        }
    }, [productNameId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleDiscountChange = (index, e) => {
        const { name, value } = e.target;
        const updatedDiscounts = [...product.discounts];
        updatedDiscounts[index] = { ...updatedDiscounts[index], [name]: value };
        setProduct({ ...product, discounts: updatedDiscounts });
    };

    const addDiscount = () => {
        setProduct({
            ...product,
            discounts: [...product.discounts, { percentOff: "", minimumQuantity: "" }]
        });
    };

    const removeDiscount = (index) => {
        const updatedDiscounts = product.discounts.filter((_, i) => i !== index);
        setProduct({ ...product, discounts: updatedDiscounts });
    };

    const handleSubmit = async (e) => 
    {
        e.preventDefault();

        if(formType === "update")
        {
            try {
                await updateProductByProductNameId(productNameId, product);
                addAlert("Product Updated", "success", 3000);
            } catch (error) {
                console.error("Failed to update product:", error);
                addAlert("Failed to Update", "error", 3000);
            }
        }else{
            try {
                const uploadResponse = await uploadProduct(product);

                const status = uploadResponse.status;
                switch(status)
                {
                    case 201:
                        addAlert("Created Product", "success", 3000);
                        break;
                    case 400:
                        addAlert("Missing Required Fields", "error", 3000);
                        break;
                    default:
                        addAlert("Unknown Error");
                        break;
                }
            } catch (error) {
                console.error("Failed to update product:", error);
            }
        }
    };

    if (!product) return <p>Loading...</p>;

    return (
        <div className="product-edit-cont">
            <form onSubmit={handleSubmit} className="product-edit-form">
                <div className="product-edit-header-cont">
                    <h2>{formType === "update" ? "Update Product" : "Upload Product"}</h2>
                    <div className="product-edit-actions-cont">
                        <button className="product-edit-action" type="submit">{formType === "update" ? "Update" : "Upload"}</button>
                        <button className="product-edit-action" onClick={() => navigate("/products")}>Go Back</button>
                    </div>
                </div>
                <div className="product-edit-form-small-items-cont">
                    <div className="product-edit-form-item">
                        <label>Title:</label>
                        <input className="product-edit-form-item-input" type="text" name="title" value={product.title} onChange={handleChange} required/>
                    </div>
                    <div className="product-edit-form-price-quantity-cont">
                        <div className="product-edit-form-item">
                            <label>Price:</label>
                            <input className="product-edit-form-item-input" type="text" name="price" value={product.price} onChange={handleChange}  required/>
                        </div>
                        <div className="product-edit-form-item">
                            <label>Quantity:</label>
                            <input className="product-edit-form-item-input" type="text" name="quantity" value={product.quantity} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="product-edit-form-item">
                    <label>Long Title:</label>
                    <input className="product-edit-form-item-input" type="text" name="longTitle" value={product.longTitle} onChange={handleChange} />
                </div>
                <div className="product-edit-form-item">
                    <label>Enhanced Description:</label>
                    <textarea className="product-edit-enhanced-description" name="enhancedDescription" value={product.enhancedDescription} onChange={handleChange}  required/>
                </div>
                <div className="product-edit-form-small-items-cont">
                    <div className="product-edit-form-item">
                        <label>Product Name ID:</label>
                        <input className="product-edit-form-item-input" type="text" name="productNameId" value={product.productNameId} onChange={handleChange}  required/>
                    </div>
                </div>
                <div className="product-edit-form-item">
                    <label>Image URL:</label>
                    <input className="product-edit-form-item-input" type="text" name="imageUrl" value={product.imageUrl} onChange={handleChange} />
                </div>
                <div className="product-edit-max-min">
                    <div className="product-edit-form-item">
                        <label>Minimum:</label>
                        <input className="product-edit-form-item-input" type="text" name="minimum" value={product.minimum} onChange={handleChange} />
                        </div>
                    <div className="product-edit-form-item">
                        <label>Maximum:</label>
                        <input className="product-edit-form-item-input" type="text" name="maximum" value={product.maximum} onChange={handleChange} />
                    </div>
                </div>

                <div className="discount-item-header-cont">
                    <h3>Discounts</h3>
                    <button className="add-discount-button" type="button" onClick={addDiscount}>Add Discount</button>
                </div>
                {product.discounts.map((discount, index) => (
                    <div key={index} className="discount-item">
                        <div className="product-edit-discount-item">
                            <div className="product-edit-form-item">
                                <label>Percent Off:</label>
                                <input
                                    className="product-edit-form-item-input" 
                                    type="text"
                                    name="percentOff"
                                    value={discount.percentOff}
                                    onChange={(e) => handleDiscountChange(index, e)}
                                />
                            </div>
                            <div className="product-edit-form-item">
                                <label>Minimum Quantity:</label>
                                <input
                                    className="product-edit-form-item-input" 
                                    type="text"
                                    name="minimumQuantity"
                                    value={discount.minimumQuantity}
                                    onChange={(e) => handleDiscountChange(index, e)}
                                />
                            </div>
                            <button 
                                className="product-edit-discount-remove-button" 
                                type="button" 
                                onClick={() => removeDiscount(index)}>Remove</button>
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
}