import "./products.css";

import { Link, useNavigate }        from "react-router-dom";

import { useProducts }              from "../../context/ProductsContext";
import { useAuth }                  from "../../context/AuthContext";

export default function Products()
{
    const { hasRole }                      = useAuth();
    
    const navigate = useNavigate();

    const { products } = useProducts();

    const handleUpdate = (productNameId) => {
        navigate(`/products/update/${productNameId}`);
    };

    const handleSerial = (productNameId) => {
        navigate(`/products/serial/${productNameId}`);
    };

    return (
        <div className="product-page-cont">
            <div className="product-page-header-cont">
                <h2 className="dashboard-page-header">Products</h2>
                <Link to={"/products/upload"} className="product-page-item">Create Product</Link>
            </div>

            <div className="product-page-products-cont">
                <div className="product-table">
                    <div>
                        <div className="p-t-header">
                            <div className="p-t-title">Title</div>
                            <div className="p-t-title">Checkouts</div>
                            <div className="p-t-price">Price</div>
                            <div className="p-t-quantity">Quantity</div>
                            <div className="p-t-actions-cont">Actions</div>
                        </div>
                    </div>
                    <div className="product-table-body">
                        {products.map((item) => (
                            <div className="p-t-row" key={item.id}>
                                <div className="p-t-title">{item.title}</div>
                                <div className="p-t-title">{item.checkouts}</div>
                                <div className="p-t-price">${item.price}</div>
                                <div className="p-t-quantity">{item.quantity}</div>
                                <div className="p-t-actions-cont">
                                    <button className="product-action-button" onClick={() => handleSerial(item.productNameId)}>Serials</button>
                                    {hasRole("ADMIN") && <button className="product-action-button" onClick={() => handleUpdate(item.productNameId)}>Update</button>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}